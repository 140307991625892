body {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
form,
div,
p,
i,
img,
ul,
li,
ol,
table,
tr,
td,
th,
fieldset,
label,
legend,
button,
input,
body {
  margin: 0;
  padding: 0;
}

.cf::after {
  display: block;
  content: '';
  clear: both;
}

.cm::before {
  display: table;
  content: '';
}

ul>li,
ol>li {
  list-style: none;
}

@font-face {
  font-family: 'hanMedium';
  src: url('./font/SourceHanSansCN-Medium.otf');
}

@font-face {
  font-family: "hanRegular";
  src: url('./font/SourceHanSansCN-Regular.otf');
}